.tableThread td:nth-child(1) {
    width: 10%;
}

.tableThread td:nth-child(2) {
    width: 10%;
}

.tableThread td:nth-child(3) {
    width: 10%;
}

.tableThread td:nth-child(4) {
    width: 40%;
}

.tableThread td:nth-child(5) {
    width: 10%;
}

.tableThread td:nth-child(6) {
    width: 10%;
}

.tableThread td:nth-child(7) {
    width: 10%;
}

.detailThread {
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
    width: 70vh;
    display: flex;
    position: relative;
}
.text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
}
.noti{
    height: 9px;
    width: 9px;
    border-radius: 9px;
    background-color: #C21A1A;;
    position: absolute;
    right: 0px;
    top: 0px;
    
}

.tableThread tr {
    border-color: #FFFFFF;
}
