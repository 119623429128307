// .filter {
//     /* min-width: fit-content; */
//     display: flex;
//     padding-bottom: 20px;
//     padding-top: 20px;
//     border-bottom: 5px solid #E7E8F3;
// }
// .filterBtn {
//     border-radius: 5px;
//     border: 1px solid grey;
//     text-align: center;
//     line-height: 42px;
//     height: 42px;
//     width: 84px;
//     margin-left: 10px;
// }
// .icon {
//     color: #4276FE;
//     font-size: 1.5em;
// }
// /* .cate {
//     border: 1px solid grey;
//     margin-left: 10px;
//     width: 268px;
//     line-height: 42px;
//     border-radius: 5px;
//     position: relative;
// } */
// .title {
//     margin-left: 10px;
//     color: #8C8C8C;
//     text-decoration: none;
//     font-size: 14px;
//     font-weight: 500;
// }
// .iconDown {
//     position: absolute;
//     right: 10px;
//     top: 12px;
// }
// .selectAr {
//     width: 268px;
//     height: 42px;
//     border-radius: 4px;
// }


.filter{
    display: flex;
    flex-direction: row;
    padding: 1rem 0.5rem;

    > :global(button){
        display: flex;
        align-items: center;
        // font-weight: bold;
        border: 1px solid #D8D8D8;
        color: #8c8c8c;
        >:global(span){
            vertical-align: middle;
        }

        >:global(svg){
            color: #4276FE;
        }

        &:global(:hover){
            background-color: white !important;
            color: #4276FE;
            border: 1px solid #4276FE;
        }

        &:global(:focus){
            outline: none !important;
            border-color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
    >:global(select){
        max-width: 200px;
        margin-left: 0.25rem;
        border: 1px solid #D8D8D8;
        color: #8c8c8c;
        cursor: pointer;
        &:global(:hover){
            background-color: white !important;
            color: #4276FE;
            border: 1px solid #4276FE;
        }
        &:global(:focus){
            outline: none !important;
            border-color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
}