.thread {
    background: #FFFFFF;
    min-height: 100vh;
    padding: 0rem 0.5rem;

    :global(.nav-tabs) {
        padding-top: 10px;

        :global(.nav-item) {
            :global(button) {
                color: black;

            }

            :global(.active) {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }

    :global(.tab-content) {
        // height: 100vh;
        // position: relative;
        :global(.fade) {
            :global(.table-content) {
                :global(.table-responsive) {
                    :global(table) {
                        :global(tbody) {
                            border-top: none;
                        }

                    }
                }
            }
        }
        // :global(.paging){
        //     position: absolute;
        //     bottom: 50px;
        //     justify-content: center;
        //     width: 100%;
        // }
        // :global(.paging){
        //     justify-content: center;
        //     width: 100%;
        // }
    }

    > :global(.content) {
        padding: 1rem 0.5rem;

        :global(.title) {
            font-weight: bold;
            text-transform: uppercase;
            color: red;

            // display: flex;
            :global(.new) {
                padding-right: 40px;

                :global(button) {
                    border: none;
                    background-color: white;
                    text-transform: uppercase;

                }
            }

            :global(.ongoing) {
                padding-right: 40px;

                :global(button) {
                    border: none;
                    background-color: white;
                    text-transform: uppercase;
                }
            }
        }


        :global(.paging) {
            text-align: center;
            padding-top: 20px;

            :global(ul) {
                margin: 0px;
                padding: 0px;
                list-style: none;

                :global(li) {
                    display: inline;
                    padding: 10px;

                    :global(button) {
                        width: 30px;
                        height: 30px;
                        text-align: center;
                        background-color: white;
                        color: black;
                        font-weight: bold;
                        border-color: black;
                    }
                }
            }
        }

        // > :global(.row){
        //     border-bottom: 5px solid #d8d8d8;
        //     margin-bottom: 1rem;
        //     &:global(:last-child){
        //         border-bottom: none;
        //     }
        // }

        :global(table) {
            > :global(:not(:first-child)) {
                border-top: none;
            }

            > :global {
                border: none;
            }

            :global(td .category-tag) {
                // background-color: #d8d8d8;
                // // text-align: center;
                // padding: 0.25rem 1rem;
                // border-radius: 1rem;
            }
        }

    }

}

// {
//     font-family: 'Quicksand';
// }
/* .des {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    width: 100vh;   
    position: relative;
    margin-right: 20px;
    width: 95%;
}
.home td:nth-child(1) {
    width: 10%;
}
.home td:nth-child(2) {
    width: 10% ;
}
.home td:nth-child(3){
    width: 45%;
}
.home td:nth-child(4) {
    width: 15%;
}
.home td:nth-child(5) {
    width: 10%;
}
.home td:nth-child(6) {
    width: 10%;
}
.tableContent {
    border-bottom: 5px solid #E5E5E5;
    padding-bottom: 30px;
}
.tableContent:last-child {
    border-width: 0px;
}
.newThreads {
    font-weight: bold;
    padding: 10px;
    margin-top: 20px;
}
.tableBorder {
    border-collapse: collapse;
    border-color: white;
}
.adminName {
    text-decoration: none;
    font-weight: bold;
    color: black;
}
.mask {
    background: #C21A1A;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
}
th, td {
    padding: 10px;
}
.topup {
    width: 85px;
    height: 26px;
    text-align: center;
    border: 1px solid grey;
    background: #E7E8F3;
    color: #494D71;
    border-radius: 36px;
} */