body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E5E5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .formCenter {
margin-top: 10%;
text-align: center;
}
.formCenter .title {
  color: #595959;
  font-size: 40px;
  margin-bottom: 5%;
}
.formField {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.formFieldLabel {
  display: block;
  text-transform: uppercase;
  font-size: 0.9em;
  color: white;
}
.formFieldInput {
  width: 20%;
  background-color: #FAFAFA;
  color: #8C8C8C;
  outline: none;
  border: 1px solid #F1F1F1;
  border-radius: 30px;
  font-size: 1em;
  font-weight: 300;
  line-height: 22px;
  padding: 10px;
}
.faq_create {
  height: 900px;
  background-color: #E5E5E5;
}
.faq_create .faqTable{
  border-collapse: collapse;
  border-color: #E5E5E5;
}
.formFieldsAddFaq {
  padding-top: 50px;
}
.formFieldAddFaq {
  padding-left: 3%;
}
.formTitle {
  padding-right: 3%;
}
.addFaq {
  width: 60%;
  height: 400px;
  border-radius: 6px;
}
.editFaq {
  font-size: 1.5em;
  color: #4276FE;
}
.deleteFaq {
  font-size: 1.5em;
  color: #EB5757;
}
.formFieldInput::placeholder {
  color: #8C8C8C;
}
.btn {
  margin-top: 8%;
}
.formFieldButton {
  text-align: center;
  background-color: #4276FE;
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 10px 60px;
  font-size: 0.8em;
  font-weight: 500;

}
.formFieldButtonCancel {
  text-align: center;
  background-color: #858690;
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 10px 60px;
  font-size: 0.8em;
  font-weight: 500;
}
.formFieldButtonSave {
  text-align: center;
  background-color: #4276FE;
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 10px 60px;
  font-size: 0.8em;
  font-weight: 500;
}
.cancel {
  padding-right: 2% ;
}
.formFieldCheckboxLabel {
  color: #646f7d;
  font-size: 0.9em;
}

.formFieldCheckbox {
  position: relative;
  top: 1.5px;

}
.note {
  font-size: "1.5em";
  color: "#4276FE";
}
.addFaq {
  background-color: #FFFFFF;
  text-align: center;
  margin: auto;

}
.headerpage {
  border-bottom: 10px solid #E7E8F3;
}
.topup {
  display: flex;
}

.iconleft {
  font-size: 1.5em;
  margin-top: 2px;
  padding-right: 5px;
}
.mainContent {
  font-size: 18px;
  color: #1D2860;
  text-align: justify;
  font-weight: 600;
}
.nav-item-img {
  padding-right: 10px;
  padding-left: 10px;
}
.content {
  background-color: #FFFFFF;
}
.converMessage {
  background-color: #E5E5E5;
  height: 700px;
}
.message {
  margin-left: 10px;
}
.user {
  padding-bottom: 10px;
}
.messageContent {
  border-radius: 40px;
  background-color: #FFFFFF;
  padding: 10px;
}
.formFieldButtonCloseThread {
  text-align: center;
  background-color: #CC3939;
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 10px 60px;
  font-size: 0.8em;
  font-weight: 500;
}
.close_thread {
  margin-right: 1.5%;
}
.formFieldButtonAddComment {
  text-align: center;
  background-color: #4276FE;
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 10px 60px;
  font-size: 0.8em;
  font-weight: 500;
}
.threadAction {
  justify-content: center;
  display: flex;
  height: 61px;
} 
.attachment {
  margin-left: 1.5%;
  margin-right: 1.5%;
}
.comment_detail {
  width: 70%;

}
.comment_detail_input {
  border-radius: 30px;
  background: #FAFAFA;
}
.attachment_icon {
  font-size: 1.5em;
  color: #1D2860;
}
.trstyle {
  border-width: 0px;
} */