div {
    /* font-family: Quicksand; */
    font-family: 'Quicksand';
}

.pageName {
    font-size: 12px;
}

.faqpage {
    background: #FFFFFF;
    min-height: 100vh;
}
.faq {
    font-weight: bold;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.faqpage td:nth-child(1) {
    width: 15%;
}

.faqpage td:nth-child(2) {
    width: 35%;
}

.faqpage td:nth-child(3) {
    width: 45%;
}

.faqpage td:nth-child(4) {
    width: 5%;
    text-align: center;
    cursor: pointer;
}

.faqpage td:nth-child(5) {
    width: 5%;
    text-align: center;
    cursor: pointer;
}

.addIcon {
    color: #2979ff;
    font-size: 2em;
    margin-left: 10px;
    cursor: pointer;
}

.tableBorder {
    margin-left: 20px;
    margin-right: 20px;
}
.editFaq {
    font-size: 1.5em;
    color: #4276FE;
}

.deleteFaq {
    font-size: 1.5em;
    color: #EB5757;
}

.answer {
    width: 90vh;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #2F80ED;
    text-decoration: underline;
}
.addFaq {
    width: 842px;
    height: 386px;
    border-radius: 6px;
    background-color: #FFFFFF;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.formFieldsAddFaq {
    padding-top: 50px;
}
.formField {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 1%;
}
.formFieldLabel {
    margin-left: 10px;
    margin-right: 20px;
    width: 60px;
}
.formFieldInputCategory {
    font-family: Quicksand;
    width: 30%;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
}
.formFieldInputQuestion {
    width: 85%;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    resize: none;
    padding: 5px;
}
.formFieldInputAns {
    width: 85%;
    height: 135px;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    resize: none;
    padding: 5px;
}
.btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    position: relative;
}
.cancel {
    padding-right: 2% ;
}
.formFieldButtonCancel {
    text-align: center;
    background-color: #858690;
    color: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 10px 60px;
    font-size: 0.8em;
    font-weight: 500;
}
.formFieldButtonSave {
    text-align: center;
    background-color: #4276FE;
    color: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 10px 60px;
    font-size: 0.8em;
    font-weight: 500;
}
.confirm {
    position: fixed;
    left: 30%;
    top: 35%;
    text-align: center;
    background-color: #FFFFFF;
    width: 490px;
    height: 181px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.text {
    padding-top: 37px;
    margin-bottom: 50px;
    color: #595959;
    
}
.btnNo {
    background: #858691;
    border: 1px solid #858691;
    width: 130px;
    height: 31px;
    border-radius: 20px;
    color: #FFFFFF;
    margin-right: 27px;
}
.btnYes {
    background: #4276FE;
    border: 1px solid #4276FE;
    width: 130px;
    height: 31px;
    border-radius: 20px;
    color: #FFFFFF;
}
.formFieldButton {
    margin-top: 30px;
    text-align: center;
    background-color: #4276FE;
    color: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 10px 60px;
    font-size: 0.8em;
    font-weight: 500;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.back {
    font-size: 1.2em;
    position: absolute;
    right: 5px;
    top: 0px;
}
.buttonBack {
    background-color: white;
    border: 1px solid white;
}