.formCenter {
    /* padding-top: 10%; */
    padding-top: 150px;
    text-align: center;
    background: #FFFFFF;
    /* height: 100vh; */
    height: calc(100vh + 150px);
}
.formField {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}
.formFieldLabel {
    display: block;
    text-transform: uppercase;
    font-size: 0.9em;
    color: white;
}
.title {
    color: #595959;
    font-size: 40px;
    margin-bottom: 5%;
}
.formFieldInput {
    /* width: 20%; */
    width: 300px;
    background-color: #FAFAFA;
    color: #8C8C8C;
    outline: none;
    border: 1px solid #F1F1F1;
    border-radius: 30px;
    font-size: 1em;
    font-weight: 300;
    line-height: 22px;
    padding: 10px;
}
.formFieldInput::placeholder {
    color: #8C8C8C;
}
.formFieldCheckboxLabel {
    color: #646f7d;
    font-size: 0.9em;
}
.formFieldCheckbox {
    position: relative;
    top: 1.5px;
  
}
.btn {
    margin-top: 8%;
    
}
.formFieldButton {
    text-align: center;
    background-color: #4276FE;
    color: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 10px 60px;
    font-size: 0.8em;
    font-weight: 500;
  
}