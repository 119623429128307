.history {
    padding: 0rem 0.5rem;
    // height: 100vh;
    min-height: 100vh;
    background-color: #FFFFFF;

    > :global(.content) {
        padding: 1rem 0.5rem;

        :global(.title) {
            font-weight: bold;
            text-transform: uppercase;
        }

        > :global(.row) {
            border-bottom: 5px solid #d8d8d8;
            margin-bottom: 1rem;

            &:global(:last-child) {
                border-bottom: none;
            }
        }

        :global(table) {
            > :global(:not(:first-child)) {
                border-top: none;
            }

            > :global {
                border: none;
            }

            :global(td .category-tag) {
                // background-color: #d8d8d8;
                // // text-align: center;
                // padding: 0.25rem 1rem;
                // border-radius: 1rem;
            }
        }

    }

}