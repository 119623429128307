.header {

    color: white !important;
    font-weight: bold;

    :global(a) {
        color: white !important;
    }

    // box-shadow:  0px 0px 10px #d8d8d8;
    // border-bottom: 8px solid #d8d8d8;
    margin-bottom: 10px;

    :global(.navbar) {
        background-color: #4276FE !important;

        :global(.navbar-nav) {
            padding: 0.25rem 0;

            :global(a.nav-link) {
                border-right: 1px solid white;

                &:global(:last-child) {
                    border-right: none;
                }
            }

        }

        :global(.name) {
            text-transform: uppercase;
            // margin: auto;
            padding-left: 30px;
        }

        :global(.searchFrom) {
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 60px;
            width: 212px;
            height: 34px;

            :global(.btn) {
                border: none;
            }

            :global(.inputText) {
                background-color: transparent;
                border: none;
                font-size: 14px;
                outline-width: 0;
                color: #FFFFFF;
                
            }
            :focus{
                outline: none;
            }
            ::placeholder {
                color: #FFFFFF;
                // opacity: 1;
                font-size: 14px;
            }
        }

        :global(a.nav-link) {
            padding: 0 1rem;

            &:global(.active),
            &:global(:hover) {
                text-decoration: underline;
            }

            span {
                vertical-align: middle;
                margin-left: 0.25rem;
            }

        }

    }

}