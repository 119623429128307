.notFound {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
}
.notFound img {
    width: 100%;
    height: 100%;
}